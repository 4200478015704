
.chatcomponent {
    display: grid;
    grid-template-columns: repeat(12, 1fr); /* I chose 12 as it is easily divisible*/
    gap: 5px;
    border: 1px solid red;
    max-height: 750px;

}

.chatcomponent__header {
    grid-column: 1 /  13;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
    text-align: center;
    border: 1px solid red;
}
.messages {
    display: grid;
    grid-column: 1 / 10;
    grid-row: 2;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
    text-align: center;
    border: 1px solid red;
    max-height: 600px;
    min-height: 550px;
}

.messages__container {
    
    max-height: 400px;
    overflow: auto;
    scroll-snap-align: start;
    border: 3px solid rgb(255, 213, 0);
}
.messages__element {
    border: 1px solid blue;
    text-align: left;
}
.input {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row: 3;
    border: 3px solid rgb(0, 255, 34);
    max-height: 45px;
    grid-row: 1000;
}

.input__box {
    grid-column: 1/3;
    border: 1px solid red;
    max-height: 20px;
}

.input__button {
    grid-column: 3;
    max-height: 20px;
    border: 1px solid red;
    min-width: 200px;
}
.chat {
    grid-column: 10 / 13;
    grid-row: 2;
    background-color: #f5f5f5;
    border: 1px solid rgb(43, 0, 255);
    max-height: 550px;
    overflow: auto;
}

.chat__header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
    text-align: center;
    border: 1px solid rgb(13, 0, 255);
}

.chat__element {
    border: 1px solid rgb(0, 13, 255);
}


